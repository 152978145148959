jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ){
    if (ns.indexOf('slick') >= 0) {
      this.addEventListener("touchstart", handle, { passive: true });
    } else {
      return false
    }
  }
};

jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ){
    if (ns.indexOf('slick') >= 0) {
      this.addEventListener("touchmove", handle, { passive: true });
    } else {
      return false
    }
  }
};
